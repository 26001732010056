import React from "react";
import { Page, Layout, Button, Card, DescriptionList } from "@shopify/polaris";
import { ErrorBanner, WebhooksStatus, ShopifyTokenStatus } from "@components";
import { useToggle } from "@hooks";

// == Types ================================================================

interface IProps {
  shopOrigin: string;
}

// == Constants ============================================================

// == Functions ============================================================

// == Component ============================================================

export default function Home({ shopOrigin }: IProps) {
  const [hasTokenStatusError, toggleTokenStatusError] = useToggle(false);
  const [hasWebhookStatusError, toggleWebhooksStatusError] = useToggle(false);

  const installScriptTags = async () => {
    try {
      const response = await fetch("/install/script_tags", { method: "GET" });
      const json = await response.json();
      // eslint-disable-next-line no-console
      console.log(json);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Page title="Status">
      <Layout>
        <Layout.Section>
          <Card sectioned title="Testing Buttons (REMOVE)">
            <Button onClick={installScriptTags}>Install ScriptTags</Button>
            {/* <Button onClick={installTheme}>Install Theme</Button>
            <Button onClick={getThemeInstallStatus}>Check Theme Install Status</Button>
            <Button onClick={installWebhooks}>Install Webhooks</Button>
            <Button onClick={getWebhooksInstallStatus}>Check Webhooks Install Status</Button>
            <Button onClick={validateToken}>Validate token</Button> */}
          </Card>
          {hasTokenStatusError && (
            <ErrorBanner message="There was a problem checking the Shopify token status." />
          )}
          {hasWebhookStatusError && (
            <ErrorBanner message="There was a problem checking the Webhooks installation." />
          )}
          <Card sectioned title="FlipGive Status">
            <DescriptionList
              items={[
                {
                  term: "Shopify Access Token",
                  description: (
                    <ShopifyTokenStatus
                      shopOrigin={shopOrigin}
                      toggleTokenStatusError={toggleTokenStatusError}
                    />
                  ),
                },
                {
                  term: "Webhooks",
                  description: (
                    <WebhooksStatus toggleWebhooksStatusError={toggleWebhooksStatusError} />
                  ),
                },
              ]}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

// == Styles ===============================================================

// https://2694850a.ngrok.io/auth?shop=flipgive-development-app.myshopify.com
// https://www.madsportsstuff.com/?ref=36c7b7f8b98911e9b655bada519ff2da&isFG=true
